.footer {
    width: 100%;
    padding: 2rem 0;
    background: rgb(249, 255, 1);
    background: linear-gradient(0deg, rgb(243, 246, 40) 0%, rgba(0, 0, 0, 0.9) 100%);
}

.footer-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}

.left {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.location,
.phone,
.mail {
    display: flex;
    margin-bottom: 0.8rem;
}

.location p {
    line-height: 30px;
}

.right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.right p {
    line-height: 30px;
    margin-bottom: 0.8rem;
}

h4 {
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
}

p {
    font-size: 1.2rem;
}

@media screen and (max-width: 640px) {
    .footer-container {
        grid-template-columns: repeat(1, 1fr);
    }

}