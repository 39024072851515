.marquee_container {
	width: 100%;
	height: 60vh;
	background-color: black;
}

.marquee_container .marquee_heading {
	text-align: center;
	padding: 20px 0;
	font-size: 3rem;
}

.marquee_content {
	border-bottom: 1px solid #ffee04;
	border-top: 1px solid #ffee04;
	width: 100%;
	height: 250px;
	padding: 50px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.marquee_content img {
	width: 300px;
	margin: 0px 100px;
}