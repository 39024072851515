.main {
    background-color: #000;
    color: #fff;
    max-width: 100%;
    height: 140vh;
}

.main_heading {
    font-size: 1rem;
    padding-top: 5rem;
    text-align: center;
}

.tablist {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
}

.tablist .tab h1 {
    padding: 15px 15px;
    font-size: 1.5rem;
    background-color: #000;
    color: #fff;
    border-bottom: 1px solid #f0de16;
    cursor: pointer;
}

.tablist .tab h1:hover {
    border-top: 1px solid #fff;
}

.skill_heading h2 {
    font-size: 2rem;
    text-align: center;
    margin-top: 2rem;
    color: #f0de16;
}

.skill_list {
    display: flex;
    justify-content: space-between;
    text-align: start;
    gap: 30px;
    padding: 10px 50px;
}

.skill_list h1 {
    text-align: left;
    margin-bottom: 10px;
}

.skill_list li {
    list-style: none;
    font-size: 1.2rem;
    padding: 10px 0;
}

.skill_list .right {
    width: 50%;

}

.skill_list .left {
    width: 50%;
}

.download {
    text-align: center;
    margin-top: 2rem;
}


/* experience css */

.exp_title {
    font-size: 2rem;
    text-align: center;
    margin-top: 2rem;
    color: #f0de16;
}

.exp_content {
    display: flex;
    justify-content: space-between;
    text-align: start;
    gap: 30px;
    padding: 10px 50px;
}

.exp_content .right {
    width: 50%;

}

.exp_content .left {
    width: 50%;
}

.exp_content .left h1 span,
.exp_content .right h1 span {
    color: #f0de16;
}

.exp_content .left p,
.exp_content .right p {
    line-height: 2rem;
}

@media screen and (max-width: 1040px) {
    .main {

        height: 150vh;
    }

    .exp_content {
        flex-direction: row;
    }
}

@media screen and (max-width: 900px) {
    .main {
        padding-top: 40%;
        height: 250vh;
    }

    .exp_content .left h1 span,
    .exp_content .right h1 span,
    .exp_content .right h1,
    .exp_content .left h1 {
        font-size: 1.5rem;
    }

    .exp_content .left p,
    .exp_content .right p {
        width: 100%;
        height: 10%;
    }
}

@media screen and (max-width: 600px) {
    .main {
        padding-top: 10%;
        height: 250vh;
    }

    .skill_list {
        display: flex;
        flex-direction: column;
    }

    .skill_list .left,
    .skill_list .right {
        width: 100%;
    }

    .exp_content {
        display: flex;
        flex-direction: column;
    }

    .exp_content .right {
        width: 100%;

    }

    .exp_content .left {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .main {
        padding-top: 1%;
        height: 320vh;
    }

    .skill_list {
        display: flex;
        flex-direction: column;
    }

    .skill_list .left,
    .skill_list .right {
        width: 100%;
    }

    .exp_content {
        display: flex;
        flex-direction: column;
    }

    .exp_content .right {
        width: 100%;

    }

    .exp_content .left {
        width: 100%;
    }
}