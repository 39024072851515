.container_form {
    background: #000;
    color: #fff;
    max-width: 100%;
    height: 120vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.container_form .form {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.container_form .form .form_title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1rem;
}

.container_form .form .row {
    width: 100%;
}

.container_form .form .row label {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
}

input,
textarea {
    width: 100%;
    height: 70%;
    background: #535252;
    color: #fff;
    margin-bottom: 0.1rem;
    border: 1px solid #eee726;
}

::placeholder {
    color: #000;
    font-style: italic;
}

.icons {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 1rem;
}

@media screen and (max-width: 1040px) {
    .container_form .form {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
    }
}

@media screen and (max-width: 900px) {
    .container_form .form {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
    }
}

/* @media screen and (max-width: 600px) {
    .container_form 
} */