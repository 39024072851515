@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Mukta", sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

::selection {
  color: #e4ec00;
  background: #000;
}

body {
  background: #0000;
  color: #fff;
}

h1,
h4,
p,
a {
  color: #fff;
  text-decoration: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #e4ec00;
  color: #000;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  background: transparent;
  color: #fff;
  padding: 12px 32px;
  font-style: 1rem;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #fff;
}

.btn:hover {
  color: linear-gradient(90deg, rgba(162, 0, 255, 1) 0%, rgba(91, 121, 255, 1) 100%, rgba(72, 58, 226, 1) 100%);
  background: #fff;
  border: 1px solid #ba4dd0;
  transition: 0.3s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10rem;
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 10;
}

.header_bg {
  background-color: rgb(0, 0, 0, 0.85);
  transition: 0.5s;
}

.nav_menu {
  display: flex;
}

.nav_menu li {
  padding: 0 1rem;
  list-style: none;
}

.nav_menu li a {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
}

.hamburger {
  display: none;
}

.counting {
  background: #00000056;
}

.count {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.count1,
.count2,
.count3 {
  padding: 50px 80px;
  text-align: center;
}

.count1 p,
.count2 p,
.count3 p {
  font-size: 2rem;
  font-weight: 800;
  background: linear-gradient(to right, #e4ec00, #ccc);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.count1:hover,
.count2:hover,
.count3:hover {
  box-shadow: 0px 0px 10px 10px #fff;
}

.counter {
  font-size: 5rem;
  font-weight: 900;
  text-align: center;
}

/* media screen */


@media screen and (max-width: 1040px) {
  .nav_menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -3;
    transition: 0.3s;
    background-color: #000;
  }

  .nav_menu li {
    padding: 1rem 0;
    color: #000;
  }

  .nav_menu.active {
    left: 0;
  }

  .header img {
    position: relative;
    right: 100%;
    width: 150px;
  }

  .nav_menu a {
    font-size: 2rem;
    color: #000;
  }

  .hamburger {
    display: initial;
    position: relative;
    right: -20%;
    color: #000;
  }
}

@media screen and (max-width: 375px) {
  .nav_menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    transition: 0.3s;
  }

  .header h1 {
    position: relative;
    right: 100%;
  }

  .nav_menu li {
    padding: 1rem 0;
  }

  .nav_menu a {
    font-size: 2rem;
  }

  .hamburger {
    display: initial;
    position: relative;
    right: -50%;
  }

}

@media screen and (max-width:420px) {
  .count {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}