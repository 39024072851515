.container {
    background: #000;
}

.project-heading {
    text-align: center;
    font-size: 2.5rem;
    padding: 3rem 0;
}

.project-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}

.project-card {
    background: #f1e64f;
    color: #000;
    padding: 1.2rem 1rem;
    border-radius: 1%;
}

.project-card:hover {
    box-shadow: 0px 0px 10px 10px #ffee04;
}

.project-card img {
    width: 100%;
    border-radius: 10%;
    object-fit: contain;
    aspect-ratio: 3/2;

}

.project-title {
    color: #000;
    padding: 1rem 0;
}

.pro-details p {
    color: #000;
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
}

.pro-btns {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}

.pro-btns .btn {
    background: #000;
    color: #ffee04;
    border: none;
}

.pro-btns .btn:hover {
    color: #fff;
}

@media screen and (max-width: 740px) {
    .project-container {
        max-width: 50%;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 600px) {
    .project-container {
        max-width: 90%;
        margin: auto;
        display: grid;
        margin: 0px 50px;
        grid-template-columns: 1fr;
    }

    .pro-btns {
        display: grid;
        grid-template-columns: 1fr;
        text-align: center;
        grid-gap: 10px;
    }
}