.hero-img {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 70vh;
    position: relative;
}

.hero-img::before {
    content: '';
    background: url("../assets/bg2.jpg") no-repeat;
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.heading h1 {
    font-size: 5rem;
}

.heading p {
    font-size: 3rem;
    text-align: center;
}

.heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 640px) {
    .hero-img h1 {
        font-size: 2.4rem;
    }

    .hero-img p {
        font-size: 1.4rem;
        text-align: center;
    }
}